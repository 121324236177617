.react-var-ui {
  background: var(--react-var-ui-background-color, #11111a);
}
.react-var-ui-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.react-var-ui-category {
  padding-bottom: 1rem;
}
.react-var-ui-category-title {
  color: var(--react-var-ui-foreground-color, #ddd);
  font-size: 0.7rem;
  padding: 0.4rem;
  text-transform: uppercase;
  text-align: left;
  user-select: none;
}
.react-var-ui-color {
  align-items: center;
  display: flex;
  margin-left: 1rem;
  position: relative;
}
.react-var-ui-color .sketch-picker {
  position: absolute;
  right: -3rem;
  top: 1rem;
}
.react-var-ui-color-color {
  border-radius: 0.2rem;
  height: 1.2rem;
  position: relative;
  width: 2.5rem;
}
.react-var-ui-color-swatch {
  background: #fff
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAAxSURBVDhPY2RgYBABYnzgDT5JxlEDGIZJGBBIBvjTCSgMCAG8CW3UAAZwQiIEaBuIACBfCLFwf0bOAAAAAElFTkSuQmCC")
    50%;
  border-radius: 0.2rem;
  cursor: pointer;
  display: inline-block;
}
.react-var-ui-color-popover {
  position: absolute;
  z-index: 2;
}
.react-var-ui-color-cover {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.react-var-ui-toggle {
  height: 1.2rem;
  position: relative;
  width: 2.5rem;
}
.react-var-ui-toggle-helper {
  background: var(--react-var-ui-input-background-color, #353542);
  border-radius: 0.2rem;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s ease-in-out;
}
.react-var-ui-toggle-helper:before {
  background-color: var(--react-var-ui-foreground-color, #ddd);
  border-radius: 1rem;
  content: "";
  height: 0.8rem;
  left: 0.3rem;
  position: absolute;
  top: 0.2rem;
  transition: all 0.2s ease-in-out;
  width: 0.8rem;
}
.react-var-ui-toggle input {
  height: 0;
  opacity: 0.01;
  width: 0;
}
.react-var-ui-toggle input:checked + .react-var-ui-toggle-helper {
  background: var(--react-var-ui-accent-color, #77f) !important;
}
.react-var-ui-toggle input:checked + .react-var-ui-toggle-helper:before {
  transform: translateX(1.1rem);
}
.react-var-ui-select select {
  background: var(--react-var-ui-input-background-color, #353542);
  border: none;
  border-radius: 0.2rem;
  color: var(--react-var-ui-foreground-color, #ddd);
  padding: 0.2rem 0.5rem;
  width: 100%;
}
.react-var-ui-select select:focus,
.react-var-ui-select select:hover {
  background: var(--react-var-ui-input-background-hover-color, #424253);
}
.react-var-ui-string-multiline,
.react-var-ui-string input {
  background: var(--react-var-ui-input-background-color, #353542);
  border: none;
  border-radius: 0.2rem;
  border-radius: 0.4rem;
  box-sizing: border-box;
  color: var(--react-var-ui-foreground-color, #ddd);
  max-width: 100%;
  min-width: 100%;
  outline: none !important;
  padding: 0.2rem 0.5rem;
  width: 100%;
}
.react-var-ui-string-multiline:focus,
.react-var-ui-string-multiline:hover,
.react-var-ui-string input:focus,
.react-var-ui-string input:hover {
  background: var(--react-var-ui-input-background-hover-color, #424253);
}
.react-var-ui-string-multiline {
  font-family: inherit;
  font-size: 0.8rem;
  grid-column: 1/3;
  margin-top: 0.4rem;
  min-height: 2rem;
  padding: 0.5rem;
}
.react-var-ui-label {
  background: var(--react-var-ui-label-background-normal-color, #22222a);
  border-bottom: 1px solid var(--react-var-ui-label-border-color, #33333a);
  color: var(--react-var-ui-foreground-color, #ddd);
  display: grid;
  font-size: 0.8rem;
  text-align: left;
  grid-template-columns: 1fr 2fr;
  padding: 0.5rem 1rem;
  user-select: none;
}
.react-var-ui-label:first-child {
  border-top: 1px solid var(--react-var-ui-label-border-color, #33333a);
}
.react-var-ui-label:hover {
  background: var(--react-var-ui-label-background-hover-color, #2a2a33);
}
.react-var-ui-label > span {
  align-items: flex-start;
  display: flex;
}
.react-var-ui-label > span:last-child {
  justify-content: flex-end;
}
.react-var-ui-label-has-label > span:first-child {
  margin-right: 0.2rem;
}
.react-var-ui-label-no-label {
  grid-template-columns: 1fr;
}
.react-var-ui-number,
.react-var-ui-slider {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.react-var-ui-number-track,
.react-var-ui-slider-track {
  background: var(--react-var-ui-input-background-color, #353542);
  border-radius: 0.2rem;
  cursor: pointer;
  flex: 1;
  height: 1.3rem;
  margin-right: 0.4rem;
  overflow: hidden;
  position: relative;
  touch-action: none;
}
.react-var-ui-number-content,
.react-var-ui-slider-content {
  background: var(--react-var-ui-accent-color, #77f);
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
}
.react-var-ui-number input,
.react-var-ui-slider input {
  -moz-appearance: textfield;
  background: var(--react-var-ui-label-background-normal-color, #22222a);
  border: none;
  border-radius: 0.25rem;
  color: var(--react-var-ui-foreground-color, #ddd);
  height: 1.3rem;
  outline: none !important;
  padding: 0 0.2rem;
  text-align: right;
  width: 2.5rem;
}
.react-var-ui-number input::-webkit-inner-spin-button,
.react-var-ui-number input::-webkit-outer-spin-button,
.react-var-ui-slider input::-webkit-inner-spin-button,
.react-var-ui-slider input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-var-ui-number span,
.react-var-ui-slider span {
  text-align: right;
  width: 2.5rem;
}
.react-var-ui-number button,
.react-var-ui-slider button {
  align-items: center;
  background: var(--react-var-ui-label-background-normal-color, #22222a);
  border: none;
  border-radius: 0.25rem;
  color: var(--react-var-ui-foreground-color, #ddd);
  cursor: pointer;
  display: flex;
  height: 1.3rem;
  justify-content: center;
  margin-left: 0.4rem;
}
.react-var-ui-number input {
  background: var(--react-var-ui-input-background-color, #353542);
}
.react-var-ui-number input:focus,
.react-var-ui-number input:hover {
  background: var(--react-var-ui-input-background-hover-color, #424253);
}
.react-var-ui-xy {
  grid-column: 1/3;
}
.react-var-ui-xy-value {
  display: inline-block !important;
  text-align: right;
}
.react-var-ui-xy-space {
  background: var(--react-var-ui-input-background-color, #353542);
  border-radius: 0.4rem;
  cursor: pointer;
  height: 250px;
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
  overflow: hidden;
  position: relative;
  touch-action: none;
  width: 100%;
}
.react-var-ui-xy-control {
  background: var(--react-var-ui-accent-color, #77f);
  border-radius: 100%;
  height: 1rem;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 1rem;
}
.react-var-ui-xy-control:after,
.react-var-ui-xy-control:before {
  background: var(--react-var-ui-accent-color, #77f);
  content: "";
  opacity: 0.5;
  position: absolute;
}
.react-var-ui-xy-control:before {
  bottom: -100rem;
  left: calc(50% - 1px);
  top: -100rem;
  width: 1px;
}
.react-var-ui-xy-control:after {
  height: 1px;
  left: -100rem;
  right: -100rem;
  top: calc(50% - 1px);
}
.react-var-ui-angle {
  align-items: center;
  display: flex;
  grid-column: 1/3;
  justify-content: center;
  padding-top: 1rem;
}
.react-var-ui-angle-value {
  justify-content: flex-end;
}
.react-var-ui-angle-control {
  background: var(--react-var-ui-input-background-color, #353542);
  border-radius: 100%;
  cursor: pointer;
  height: 6rem;
  position: relative;
  touch-action: none;
  width: 6rem;
}
.react-var-ui-angle-control:after {
  background: var(--react-var-ui-accent-color, #77f);
  border-radius: 100%;
  content: "";
  display: block;
  height: 1rem;
  left: calc(50% - 0.5rem);
  position: absolute;
  top: 0.5rem;
  width: 1rem;
}
.react-var-ui-file,
.react-var-ui-image {
  align-items: center;
  background-color: var(--react-var-ui-input-background-color, #353542);
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  grid-column: 1/3;
  height: 150px;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
  overflow: hidden;
  position: relative;
  touch-action: none;
  width: 100%;
}
.react-var-ui-file-value,
.react-var-ui-image-value {
  display: inline-block !important;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.react-var-ui-file-background,
.react-var-ui-file-metadata,
.react-var-ui-image-background,
.react-var-ui-image-metadata {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.react-var-ui-file-background,
.react-var-ui-image-background {
  background-position: 50% 50%;
  background-size: cover;
  filter: brightness(0.5) grayscale(0.5);
  transform: scaleX(1);
  transition: all 0.2s ease-in-out;
}
.react-var-ui-file-metadata,
.react-var-ui-image-metadata {
  padding: 10px;
}
.react-var-ui-file:hover .react-var-ui-file-background,
.react-var-ui-file:hover .react-var-ui-image-background,
.react-var-ui-image:hover .react-var-ui-file-background,
.react-var-ui-image:hover .react-var-ui-image-background {
  filter: brightness(0.7) grayscale(0.2);
  transform: scale3d(1.05, 1.05, 1);
}
.react-var-ui-file svg,
.react-var-ui-image svg {
  height: 25px;
  pointer-events: none;
  width: 25px;
  z-index: 1;
}
.react-var-ui-file input,
.react-var-ui-image input {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0.001;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.react-var-ui-button button {
  background: var(--react-var-ui-input-background-color, #353542);
  border: none;
  border-radius: 0.4rem;
  color: var(--react-var-ui-foreground-color, #ddd);
  cursor: pointer;
  padding: 0.4rem 1rem;
  width: 100%;
}
.react-var-ui-button button:hover {
  background: var(--react-var-ui-input-background-hover-color, #424253);
}
.react-var-ui-button button:active {
  background: var(--react-var-ui-input-background-pressed-color, #2b2b37);
}
