.panel {
  /* position: relative; */
  height: 95vh;
  width: 26%;
  padding-right: 1.25%;
  background: #181c20;
  z-index: 1000;
  padding-top: 30px;
  overflow-y: scroll;
  overscroll-behavior: contain;
}
.scene {
  position: relative;
  height: 100vh;
  width: 72.5%;
  background: #181c20;
  z-index: 1000;
  overflow-y: scroll;
}
